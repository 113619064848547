import React from 'react';

const ResponseContent = ({
  limit = 20,
  page = 1,
  rangeFrom,
  rangeTo,
  total,
  items = []
}) => {
  const response = `
    {
      items: ${items.length ? '[...]' : '[]'}
      limit: ${limit},
      page: ${page},
      rangeFrom: ${rangeFrom},
      rangeTo: ${rangeTo},
      total: ${total}
    }
  `;

  return (
    <div>
      <pre style={{ whiteSpace: 'pre-wrap', color: 'white' }}>
        {response}
      </pre>
    </div>
  );
};

export default ResponseContent;

import React from 'react';

const Tabs = ({
  activeTab,
  setActiveTab,
  setEndpoint,
  className = ''
}) => {
  return (
    <div className={className}>
      <ul
        className="nav nav-tabs"
      >
        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'content' ? 'active' : 'bg-light border-top border-left border-right'}`}
            type="button"
            onClick={() => {
              setActiveTab('content');
              setEndpoint('');
            }}
          >
            Content
          </button>
        </li>

        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'actions' ? 'active' : 'bg-light border-top border-left border-right'}`}
            type="button"
            onClick={() => {
              setActiveTab('actions');
              setEndpoint('');
            }}
          >
            Actions
          </button>
        </li>

      </ul>
    </div>
  );
};

export default Tabs;

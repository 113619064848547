import React, { Component } from 'react';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';

// REQUIRED props
// ***********************
// onFileUploadCallback @func
// organizationId @str
// accept @str
// ************************

class FileUploader extends Component {
  callback = (fileObj) => {
    this.props.onFileUploadCallback(fileObj);
  }

  onFileUploadHook = (e) => {
    const {
      organizationId,
      awsPrefix = AWS_USER_PREFIX
    } = this.props;
    const { files } = e.currentTarget;
    const blob = files[0];

    aws.uploadFile({
      prefix: `${awsPrefix}/${organizationId}`,
      file: blob,
      organizationId
    }).then((url) => {
      const fileObj = { url };

      if (blob.name) fileObj.name = blob.name;
      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      // Add file as embed syntax in editor
      this.callback(fileObj);
    });
  }

  render() {
    const {
      id, name, style, accept
    } = this.props;
    const defaultId = 'fileUploader';

    return (
      <input
        id={id || defaultId}
        name={name || id || defaultId}
        style={style}
        type="file"
        onChange={this.onFileUploadHook}
        accept={accept || 'application/pdf, application/vnd.ms-excel'}
      />
    );
  }
}

export default FileUploader;

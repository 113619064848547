import React, { useMemo } from 'react';
import {
  // FaFolderOpen,
  FaEllipsisH,
  // FaFolderMinus,
  // FaLink,
  FaPencilAlt,
  FaTimes
} from 'react-icons/fa';
// import { toast } from 'react-toastify';
// import { MdDriveFileMove } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSideSheet
  // setSideSheet
} from '../../../actions/SideSheets';
// import { canCreateResource } from '../../../services/currentUser';
// import canUnpublishMaterial from '../../../permissions/manage/canUnpublishMaterial';
// import copyToClipBoard from '../../../utils/copyToClipBoard';
// import { SHEET_KEY_MATERIAL_ADD } from '../../../constants/sideSheets';
// import { MODAL_KEY_CONFIRM_ACTION } from '../../../constants/modals';
// import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
// import { unpublishMaterial } from '../../../services/materials';
// import { getResourcesByParentId } from '../../../actions/Resources';
// import { TURBINE_ADMIN } from '../../../constants/urls';
// import { ORG_CONSOLE_RESOURCES } from '../../../constants/routes';
import canEditMaterial from '../../../permissions/manage/canEditMaterial';

const DotMenu = ({
  editMode,
  setEditMode
}) => {
  const dispatch = useDispatch();
  // Redux
  // const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // const resourceGroup = useSelector((state) => state.resourceGroup);
  const material = useSelector((state) => state.material);
  // Organization
  // const orgType = organization?.type || null;
  // Material
  const materialUserId = material?.userId || null;
  // Current User
  const userId = currentUser?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  // const resourceLinkToCopy = useMemo(() => {
  //   const baseUrl = TURBINE_ADMIN + ORG_CONSOLE_RESOURCES;
  //   const urlParams = new URLSearchParams({
  //     fileId: material?.resourceId
  //   });

  //   return `${baseUrl}?${urlParams.toString()}`;
  // }, [material?.resourceId]);

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded bg-white py-1 border border-1"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Menu"
          title="Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white text-nowrap">
            {material?.resourceId ? 'Material Resource' : 'Material'}
          </div>
          {!editMode && canEditMaterial(role, 'materials', userId, materialUserId) && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              type='button'
              onClick={() => {
                setEditMode(true);
              }}
            >
              <span className='d-flex align-items-center'>
                <FaPencilAlt size={15} />
                <span
                  className='small'
                  style={{
                    marginLeft: '12px'
                  }}
                >
                  Edit
                </span>
              </span>
            </button>
          )}

          {/* {!editMode && material?.apprenticeshipId === null && (
            <button
              type="button"
              title='Copy link'
              className="dropdown-item border-bottom py-2 px-3"
              disabled={!material?.resourceId}
              onClick={() => {
                copyToClipBoard(resourceLinkToCopy);
                toast.success('Link Copied!');
              }}
            >
              <span className='d-flex align-items-center'>
                <FaLink size={15} />
                <span
                  className='small'
                  style={{
                    marginLeft: '12px'
                  }}
                >
                  Copy link
                </span>
              </span>
            </button>
          )} */}

          {/* {!editMode && material?.resourceId && !resourceGroup?.id && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              type='button'
              onClick={() => {
                window.location.assign(resourceLinkToCopy);
              }}
            >
              <span className=' d-flex align-items-center justify-content-between'>
                <span className='d-flex align-items-center'>
                  <FaFolderOpen size={15} />
                  <span className='ml-2 small'>
                    Open in Resources
                  </span>
                </span>
              </span>
            </button>
          )} */}

          <button
            className="dropdown-item py-2 px-3"
            type='button'
            onClick={() => {
              dispatch(resetSideSheet());
            }}
          >
            <span className='d-flex align-items-center'>
              <FaTimes size={18} />
              <span className='ml-2 small'>
                Close
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;

import serializeCurrentUser from '../serializers/currentUser';
import { fetchSupabaseEntries } from './supabaseProxy';

/**
 *
 * @param {array} userIds
 * @param {string} searchValue
 * @param {string} orgId // contentful ID
 * @param {number} limit // number of records per page, max is 200
 * @param {number} include // contentful depth of record, re: references
 * @param {string} order // default sort order, ex: 'fields.name'
 * @param {bool} onlyActive // get users with membership that is active or not
 * @returns {Promise} // array of users (flattened)
 */
export const fetchUsers = async ({
  userIds,
  searchValue,
  roles,
  orgId,
  userProfileId,
  orgType,
  locationId,
  limit = 200,
  page = 1,
  order = 'name',
  uniqueCertificateId,
  excludeInactive = true,
  excludeTestUsers,
  select
}) => {
  const config = {
    orgId,
    limit,
    order,
    page
  };

  if (orgId) {
    config['f.orgId[eq]'] = orgId;
  }

  if (userProfileId) {
    config['f.userProfileId[eq]'] = userProfileId;
  }

  if (searchValue !== undefined) {
    config['f.name[ilike]'] = searchValue;
  }

  if (Array.isArray(roles) && roles.length !== 0) {
    config['f.role[ov]'] = roles.join(',');
  }

  if (orgType === 'community') {
    config['f.role[!ov]'] = 'student';
  }

  if (Array.isArray(userIds) && userIds.length !== 0) {
    config.ids = userIds.join(',');
  }

  if (locationId !== undefined) {
    config['f.locationId[eq]'] = locationId;
  }

  if (uniqueCertificateId !== undefined) {
    config['f.uniqueCertificateIds[ov]'] = uniqueCertificateId;
  }

  if (excludeInactive) {
    config['f.membership[!ov]'] = 'inactive';
  }

  if (excludeTestUsers !== undefined) {
    config['f.testUser[is]'] = null;
  }

  if (Array.isArray(select) && select.length !== 0) {
    config.select = select.join(',');
  }

  try {
    const response = await fetchSupabaseEntries(config, 'users');

    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const fetchUser = async ({ userId, select }) => {
  const errorUserIdRequired = 'userId is required';

  if (!userId) {
    console.error(errorUserIdRequired);
    throw new Error(errorUserIdRequired);
  }

  try {
    const params = { id: userId };

    if (Array.isArray(select) && select.length) {
      params.select = select.join(',');
    }

    const response = await fetchSupabaseEntries(params, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;

    // TODO refactor & rm serializeCurrentUser
    const currentUser = user ? serializeCurrentUser(user) : null;

    return currentUser;
  } catch (error) {
    console.error(error, { userId });
    throw new Error('User not found');
  }
};

import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchMaterials } from '../../../../services/materials';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import ListView from '../../../../components/lists/MaterialsList/ListView';

const MaterialsModule = ({ orgId, ids, showNoDataUi = false }) => {
  // Local State
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  const hasDataIds = useMemo(() => {
    return Array.isArray(ids) && ids?.length > 0;
  }, [ids]);

  useEffect(() => {
    if (hasDataIds) {
      fetchMaterials({
        orgId,
        ids,
        sortByIds: ids
      }).then((response) => {
        const items = response?.items || [];
        if (items.length > 0) {
          setData(items);
        }
      }).finally(() => {
        setDataFetched(true);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [hasDataIds, ids, orgId]);

  if (loading) {
    return (
      <div
        className="row"
        style={{
          minHeight: '60px'
        }}
      >
        <div className='col-6'>
          <Skeleton
            style={{ height: '60px', width: '100%' }}
          />
        </div>
        <div className='col-6'>
          <Skeleton
            style={{ height: '60px', width: '100%' }}
          />
        </div>
      </div>
    );
  }

  if (hasDataIds && dataFetched && !data) {
    if (showNoDataUi) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center justify-content-center"
          style={{
            minHeight: '400px'
          }}
        >
          <img
            src={IMAGE_TOOLKIT_EMPTY}
            className="mb-3"
            height={150}
            style={{
              height: '150px'
            }}
            alt="No Materials"
          />
          <p className="mt-1 mb-3">No Materials.</p>
        </div>
      );
    }
  }

  return (
    <ListView data={data} />
  );
};

export default MaterialsModule;

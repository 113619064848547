import { client, flattenItem } from '../services/contentful';
import {
  RESET_MATERIAL,
  RESET_MATERIALS,
  // SET_ENTRY_ORG_ID,
  SET_MATERIAL

} from './types';
import { fetchMaterial } from '../services/materials';
import { getResourcesByParentId } from './Resources';

const table = 'materials';

export const getMaterial = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterial(id)
        .then((material) => {
          if (!material?.id) {
            reject(new Error('No material found (id)'));
            return;
          }

          // ORG CHECK
          // dispatch({
          //   type: SET_ENTRY_ORG_ID,
          //   entryOrgId: material?.orgId || null
          // });

          dispatch({
            type: SET_MATERIAL,
            material: material?.id ? { ...material, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(material?.id, 'materials'));
          resolve(material);
        })
        .catch((error) => {
          console.error(`get ${table}`, error);
          reject(error);
        });
    });
  };
};

export const setMaterial = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      client.getEntry(id).then((response) => {
        const material = response ? flattenItem(response) : null;

        dispatch({
          type: SET_MATERIAL,
          material,
          cdnFiles: material?.integration?.cdnFiles || null
        });
        resolve(material);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetMaterial = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIAL });
  };
};

export const resetMaterials = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIALS });
  };
};

import React from 'react';

const Filters = () => {
  const fEqual = `
    ?f.type[eq]=link
  `;
  const fGreaterThan = `
    ?f.updatedAt[gt]=2025-02-24 20:58:56.941953+00
  `;
  const fLessThan = `
    ?f.createdAt[lt]=2025-02-24 20:58:56.941953+00
  `;
  const fILike = `
    ?f.name[ilike]=%alan%
  `;
  const fIs = `
    ?f.name[is]=null
  `;
  const fIsNot = `
    ?f.name[!is]=null
  `;
  const fIn = `
    ?f.id[in]=xyz,abc
  `;
  const fOv = `
    ?f.parentIds[ov]=ff03n3bfu4
  `;
  const fOvNot = `
    ?f.parentIds[!ov]=ff03n3bfu4
  `;

  return (
    <div>
      <div className='mb-3'>
        <h6>
          Equals
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fEqual}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Greater Than
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fGreaterThan}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Less Than
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fLessThan}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Match case-insensitive pattern
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fILike}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Is value
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fIs}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Is <strong>NOT</strong> value
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fIsNot}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          In value
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fIn}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          With a common element
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fOv}
          </pre>
        </div>
      </div>
      <div className='mb-3'>
        <h6>
          Without a common element
        </h6>
        <div className='bg-dark border rounded'>
          <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 0, color: 'white' }}>
            {fOvNot}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Filters;

import { SUPABASE_BASE_URL } from '../constants/api';
import * as twApi from './tw-api';

export async function searchSupabaseEntries({
  table,
  column,
  lookup = '',
  limit = 6,
  version = 'v1'
}) {
  const params = {
    table,
    column,
    lookup,
    limit
  };

  const urlParams = new URLSearchParams(params);
  const url = `/${version}${SUPABASE_BASE_URL}/search`;
  const response = await twApi.get(`${url}?${urlParams.toString()}`);

  return response;
}

export async function fetchSupabaseEntry({
  id,
  table,
  select,
  version = 'v1'
}) {
  const url = `/${version}${SUPABASE_BASE_URL}/${table}`;

  try {
    const response = await twApi.get(url, {
      id,
      ...(select ? { select } : {})
    });
    // TODO return object, not array
    const entry = Array.isArray(response?.items) && response?.items[0] ? response?.items[0] : null;

    return entry;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

/**
 * [NEW] Get Supabase entries
 *
 * @param {} params
 * @param {string} params.ids - comma separate string of IDs
 *
 * Field queries with operators: (up to 5 supported)
 * @param {string} ['f.locationId[eq]']
 * @param {string} ['f.locationId[ne]']
 * @param {string} ['f.apprenticeshipIds[ov]']
 * @param {string} ['f.apprenticeshipIds[is]'] // null, true, false
 * @param {string} table
 * @param {string} version
 *
 *@abstract https://api.turbineworkforce.com/swagger/static/index.html#/supabase/get_v1_external_supabase__table_
 *
 * @returns {Promise<string>} - firebase documents
 */
export async function fetchSupabaseEntries(params = {}, table = '', version = 'v1') {
  let query = params;

  // ids take precedence over all other queries
  if (params?.ids) {
    query = {
      ids: params?.ids,
      orgId: params?.orgId,
      ...(params?.order ? { order: params?.order } : {}),
      ...(params?.select ? { select: params?.select } : {})
    };
  }

  const url = `/${version}${SUPABASE_BASE_URL}/${table}`;

  try {
    const response = await twApi.get(url, query);

    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

/**
 * @summary Adds (sets) document to Supabase table, custom ID
 * @param {object} data // key "id" when excluded will generate a custom ID
 * @param {string} table
 * @param {string} version
 * @returns return { id: '', table: '', data: {} }
 * @abstract https://api.turbineworkforce.com/swagger/static/index.html#/supabase/post_v1_external_supabase__table_
 */
export async function createSupabaseEntry({
  table,
  data,
  version = 'v1'
}) {
  const dataToSave = JSON.stringify(data);
  const endpoint = `/${version}${SUPABASE_BASE_URL}/${table}`;
  const response = await twApi.post(endpoint, '', dataToSave);

  return response;
}

/**
 * @summary Update Supabase entry by id
 * @param {string} table - supabase table
 * @param {string} id - supabase entry id
 * @param {object} data
 * @param {string} version
 * @abstract https://api.turbineworkforce.com/swagger/static/index.html#/supabase/put_v1_external_supabase__table_
 * @returns {Promise<string>} - supabase document object.
 */
export async function updateSupabaseEntry({
  table,
  id,
  data,
  version = 'v1'
}) {
  const dataToSave = JSON.stringify({ id, ...data });
  const endpoint = `/${version}${SUPABASE_BASE_URL}/${table}`;
  const response = await twApi.put(endpoint, '', dataToSave);

  return response;
}

/**
 * @summary Delete document from Supabase table
 * @param {object} data // { id }
 * @param {string} table
 * @param {string} version
 * @abstract https://api.turbineworkforce.com/swagger/static/index.html#/supabase/delete_v1_external_supabase__table_
 */
export async function deleteSupabaseEntry({
  table,
  data,
  version = 'v1'
}) {
  const body = JSON.stringify(data);
  const endpoint = `/${version}${SUPABASE_BASE_URL}/${table}`;
  const response = await twApi.requestDelete(endpoint, '', body);

  return response;
}

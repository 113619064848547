import React from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';

const TagInput = ({
  id, name, tags = [], onChange, disabled
}) => {
  return (
    <div className={disabled ? 'pointer-events-none' : ''}>
      <ReactTagInput
        id={id || 'tags'}
        name={name || 'tags'}
        tags={tags}
        onChange={(newTags) => {
        // setTags(newTags);
          if (onChange) {
            onChange({ [name]: newTags });
          }
        }}
      />
    </div>
  );
};

export default TagInput;

import React from 'react';

function ResponseAsJson({
  response
}) {
  return (
    <div
      className='bg-white p-3 rounded shadow border py-2 px-1 rounded mb-2'
    >
      <pre>
        {JSON.stringify(response, null, 2)}
      </pre>
    </div>
  );
}

export default ResponseAsJson;

import React from 'react';

const ResponseActions = ({
  endpoint
}) => {
  console.log('endpoint', endpoint);
  let rsp = '';
  if (endpoint === 'actions/create-public-url') {
    rsp = `
      {
        url: string
      }
    `;
  }

  return (
    <div>
      <pre style={{ whiteSpace: 'pre-wrap', color: 'white' }}>
        {rsp}
      </pre>
    </div>
  );
};

export default ResponseActions;

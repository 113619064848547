export const GMAIL_SEND = '/v1/external/gmail/send';
export const FIREBASE_STORE_COLLECTION = '/v1/external/firebase/store';
export const OPEN_API_CREATE_COMPLETION = '/v1/external/openai/create-completion';
export const ANALYTICS_TRACK = '/v1/analytics/track';
export const CHECK_ACCESS_CODE = '/v1/access/checkAccessCode';

// LLM
export const API_LLM_COMPLETIONS_CHAT_ENGINE = '/v1/llm/completions/chatEngine';

// Supabase
export const SUPABASE_BASE_URL = '/external/supabase';
export const SUPABASE_VECTOR_SEARCH = '/v1/external/supabase/vectorSearch';
export const SUPABASE_CONTENT_TYPES = [
  'materials',
  'projects', // Projects do NOT allow users to upload images/files in WYSIWYG
  'notifications',
  'resourceGroup' // TODO change to "resourceGroups"
];
// PAGINATION
export const DEFAULT_PAGINATION_LIMIT = 20;
// EMBED
export const API_EMBED_MATERIAL = '/v1/embed/material';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FaExclamationTriangle, FaEyeSlash } from 'react-icons/fa';
import PoweredBy from '../../components/auth/Container/PoweredBy';

const PageLinkMessage = () => {
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const paramsId = searchParams.get('id');
  // Invite Message
  const messageType = params?.messageType || null;
  const title = useMemo(() => {
    if (messageType === 'inactive') {
      return 'Inactive Page';
    }

    return '404';
  }, [messageType]);

  const body = useMemo(() => {
    if (messageType === 'inactive') {
      return 'This page is no longer active.';
    }

    return 'No page found';
  }, [messageType]);

  return (
    <div
      className='d-flex align-items-center justify-content-center flex-column'
      style={{
        height: '100vh'
      }}
    >
      <div
        className='card mt-3'
        style={{
          minWidth: '340px'
        }}
      >
        <div className='card-header text-center h5 m-0 font-weight-bold'>
          {title}
        </div>
        <div className='card-body text-center'>
          <Icon messageType={messageType} />

          <p className='mt-3'>
            {body}
          </p>
        </div>
        <div className='card-footer text-center'>
          {paramsId || '...'}
        </div>
      </div>
      <div className="my-3 text-muted">
        <PoweredBy
          trackingCode="inviteMessage"
        />
      </div>
    </div>
  );
};

const Icon = ({ messageType }) => {
  if (messageType === 'inactive') {
    return (
      <FaEyeSlash
        className='text-muted mx-auto'
        size={36}
      />
    );
  }

  return (
    <FaExclamationTriangle
      className='text-danger mx-auto'
      size={36}
    />
  );
};

export default PageLinkMessage;

import React from 'react';

function ItemsList({
  items
}) {
  return (
    <div>
      {items.map((item) => (
        <div
          key={item.id}
          className='bg-white p-3 rounded shadow border py-2 px-1 rounded mb-2'
        >
          <pre>
            {JSON.stringify(item, null, 2)}
          </pre>
        </div>
      ))}
    </div>
  );
}

export default ItemsList;

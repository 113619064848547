import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROBOTS_OFF } from '../../constants/globals';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import TopNav from '../TopNav';
import TopNavCoursePage from '../CoursePage/TopNav';
import TopNavPathwayPage from '../PathwayPage/TopNav';
import TopNavEmployerPages from '../TopNav/TopNavEmployerPages';
import Login from '../auth/Login';
import SSO from '../auth/SSO';
// import Organizations from '../Organizations';
// import ConsoleBilling from '../OrgConsole/Billing';
// import Organization from '../Organization';
import Modals from '../Modals';
import Slash from '../Slash';
import Pathways from '../Pathways';
import CoursePage from '../CoursePage';
import PathwayPage from '../PathwayPage';
import JobProfilePage from '../JobProfilePage';
import PathwayPageJobProfile from '../PathwayPage/JobProfile';
import PathwayPageCertificates from '../PathwayPage/Certificates';
import Document from '../Document';
import Invite from '../Invite';
import InviteMessage from '../Invite/InviteMessage';
import FileParse from '../FileParse';
import Assistant from '../Assistant';
import PageLink from '../../routes/PageLink';
import PageLinkMessage from '../../routes/PageLink/PageLinkMessage';
import Page from '../../routes/Page';
import SideSheets from '../SideSheets';
import '../../styles/colors.css';
import '../../styles/cards.css';
import '../../styles/buttons.css';
import '../../styles/embed.css';
import '../../styles/forms.css';
import '../../styles/modals.css';
import '../../styles/tables.css';
import '../../styles/timeline.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './style.css';
import Api from '../../routes/Api';

const App = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content={ROBOTS_OFF} />
        <title>Turbine Pages</title>
      </Helmet>
      <Router>
        <>
          <TopNav />
          <TopNavCoursePage />
          <TopNavPathwayPage />
          <TopNavEmployerPages />
          <div
            className="container-fluid px-3"
          >
            <Switch>
              <Route exact path={ROUTES.SSO} component={SSO} />
              <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.INVITE_MESSAGE} component={InviteMessage} />
              <Route path={ROUTES.INVITE} component={Invite} />
              <Route path={ROUTES.PAGE_LINK_MESSAGE} component={PageLinkMessage} />
              <Route path={ROUTES.PAGE_LINK} component={PageLink} />
              <Route path={ROUTES.PAGE} component={Page} />
              <Route path={ROUTES.DOCUMENT} component={Document} />
              <Route path={ROUTES.COURSE_PAGE} component={CoursePage} />
              <Route path={ROUTES.PATHWAY_PAGE_JOB_PROFILE} component={PathwayPageJobProfile} />
              <Route path={ROUTES.PATHWAY_PAGE_CERTIFICATES} component={PathwayPageCertificates} />
              <Route path={ROUTES.PATHWAY_PAGE} component={PathwayPage} />
              <Route path={ROUTES.JOB_PROFILE_PAGE} component={JobProfilePage} />
              {/* <Route path={ROUTES.ORG_MANAGE} component={Organization} /> */}
              {/* <Route path={ROUTES.ORG_CONSOLE_BILLING} component={ConsoleBilling} /> */}
              {/* <Route path={ROUTES.ORGANIZATIONS} component={Organizations} /> */}
              <Route exact path={ROUTES.ASSISTANT} component={Assistant} />
              <Route exact path={ROUTES.PATHWAYS} component={Pathways} />
              <Route exact path={ROUTES.FILE_PARSE} component={FileParse} />
              <Route exact path={ROUTES.API} component={Api} />
              <Route exact path={ROUTES.SLASH} component={Slash} />
              <Route component={Slash} />
            </Switch>
          </div>
          <SideSheets />
          <Modals />
          <ToastContainer
            className="text-center"
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
        </>
      </Router>
    </>
  );
};

export default withAuthentication(App);

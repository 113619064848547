import * as twApi from './tw-api';
import { CHECK_ACCESS_CODE } from '../constants/api';

export const checkAccessCode = async ({
  table,
  id,
  accessCode
}) => {
  if (!id) {
    throw new Error('id is required');
  }

  if (!table) {
    throw new Error('table is required');
  }

  const data = {
    id,
    table,
    accessCode
  };

  try {
    const response = await twApi.post(CHECK_ACCESS_CODE, '', JSON.stringify(data));

    return response;
  } catch (error) {
    console.error(`access/checkAccessCode ${table}`, error);
    throw new Error(error);
  }
};

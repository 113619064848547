import {
  SET_SIDE_SHEET,
  RESET_SIDE_SHEET
  // UPDATE_SIDE_SHEET

} from './types';

export const setSideSheet = (sideSheet) => {
  document.body.classList.add('turbine-side-sheet-active');

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SIDE_SHEET,
        sideSheet: {
          ...sideSheet
        }
      });
      resolve();
    });
  };
};

// export const updateSideSheet = (data) => {
//   return (dispatch) => {
//     return new Promise((resolve) => {
//       dispatch({
//         type: UPDATE_SIDE_SHEET,
//         payload: data
//       });
//       resolve();
//     });
//   };
// };

export const resetSideSheet = () => {
  document.body.classList.remove('turbine-side-sheet-active');

  return (dispatch) => {
    dispatch({ type: RESET_SIDE_SHEET });
  };
};

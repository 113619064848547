import React from 'react';
import RenderMarkdown from '../../../components/ManageContent/RenderMarkdown';

const MaterialBlock = ({
  id = '',
  className = '',
  body
}) => {
  return (
    <RenderMarkdown
      id={`MaterialBlock-${id}`} // Allows for updating Material Module via innerHTML after editing in SideSheet
      source={body}
      enableNewEditor
      className={`MaterialBlock ${className}`}
    />
  );
};

export default MaterialBlock;

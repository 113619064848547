import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import qs from 'query-string';
import prettyBytes from 'pretty-bytes';
import { deleteFile } from '../../../services/aws';
import { canDeleteFile } from '../../../services/currentUser';
import { idFromContentType } from '../../../services/entry';
import { updateCdnFiles } from '../../../actions';
import Modal from '../../Modal';
import './style.css';

function fileType(type) {
  switch (type) {
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: 'far fa-file-image'
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: 'far fa-file-pdf'
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: 'far fa-file-excel'
      };
    default:
      return {
        name: 'file',
        icon: 'far fa-file'
      };
  }
}

const initialState = {
  showDialogConfirmRemoveItem: false,
  itemToRemove: null
};

class CdnFiles extends Component {
  state = {
    ...initialState
  }

  handleRemove = () => {
    const { itemToRemove } = this.state;
    if (!itemToRemove) return false;

    const { url, name } = itemToRemove;
    const { organization } = this.props;
    const queryParams = qs.parse(window.location.search);
    const {
      contentType, classId, topicId, entryId
    } = queryParams;
    const { id: orgId } = organization;

    deleteFile({
      url,
      orgId
    }).then(() => {
      this.props.updateCdnFiles({
        file: itemToRemove,
        id: idFromContentType({
          contentType, classId, topicId, entryId
        }),
        type: contentType
      }).then(() => {
        toast.success(`${name || 'File'} deleted!`);
        this.setState({ ...initialState }); // Close modal, state clean up
      });
    });
  }

  render() {
    const { itemToRemove } = this.state;
    const {
      id, title, currentUser, files
    } = this.props;
    if (!files || !currentUser) return null;

    const role = currentUser?.role || [];

    const dialogConfirmRemoveItem = {
      visible: this.state.showDialogConfirmRemoveItem,
      title: `Delete ${itemToRemove ? this.state.itemToRemove.name : 'File'}`,
      text: 'This action cannot be undone',
      primaryButton: {
        func: this.handleRemove,
        text: 'Yes'
      },
      secondaryButton: {
        func: () => {
          this.setState(initialState);
        },
        text: 'No'
      },
      close: () => {
        this.setState(initialState);
      }
    };

    /**
     * @summary - array of files stored on external CDN
     * @type {string} 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/vnd.ms-excel'
     * @name {string} 'cool-file.png'
     * @size {int} 301082
     * @url {string} 'https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/1a120758-389e-43b2-be2a-78c736e814b9.jpg'
     */

    return (
      <Fragment>
        <div id={id || 'integration-files'} className="card my-4">
          <div className="card-header bg-dark">
            <h5 className="m-0 text-white">
              {title || 'Files'}
            </h5>
          </div>
          <div className="cdn-files card-body">
            <table className="table table-striped th-border-top-0">
              <thead>
                <tr>
                  <th scope="col">File</th>
                  <th scope="col">Size</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, i) => {
                  const {
                    name, type, size, url
                  } = file;
                  const { name: typeName, icon: typeIcon } = fileType(type);

                  return (
                    <tr id={`file--${name}--${size}`} key={i}>
                      <td className="d-none d-md-table-cell">
                        {typeName === 'image' ? (
                          <img src={url} alt={name} height="30" style={{ height: '30px' }} />
                        ) : (
                          <span style={{ fontSize: '1rem' }}>
                            <i className={typeIcon} />
                          </span>
                        )}
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="View in new tab"
                          className="ml-2"
                        >
                          {name} <small className="ml-1"><i className="fas fa-external-link-alt" /></small>
                        </a>
                      </td>

                      <td className="d-none d-md-table-cell">
                        {prettyBytes(size)}
                      </td>

                      <td className="d-none d-md-table-cell text-right">
                        {canDeleteFile(role) && (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => {
                              this.setState({
                                showDialogConfirmRemoveItem: true,
                                itemToRemove: file
                              });
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {this.state.showDialogConfirmRemoveItem && (
          <Modal.Dialog {...dialogConfirmRemoveItem} />
        )}
      </Fragment>
    );
  }
}

// {
//   "url": "https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/37126d32-abd9-4849-9f2e-8fb0b39ee411.jpg",
//   "name": "portfolio--example-5.jpg",
//   "type": "image/jpeg",
//   "size": 301082
// }

const mapStateToProps = ({ currentUser, organization }) => ({ currentUser, organization });

export default connect(mapStateToProps, { updateCdnFiles })(CdnFiles);

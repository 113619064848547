import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaFileAlt } from 'react-icons/fa';
// import { MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import { updateMaterial } from '../../services/materials';
import { updateResource } from '../../services/resources';
import { getResourcesByParentId } from '../../actions/Resources';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
// import EntryButton from '../btns/EntryButton';
import { embedMaterial } from '../../services/openAi';

const Form = ({
  showHeader = false,
  // onCancel,
  onSuccess,
  contentType,
  uploadPdfButton = false,
  embedMediaButton = false,
  tinyMceEditor = {}
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const bodyRef = useRef(null);
  // Redux
  // const course = useSelector((state) => state.course);
  // const currentTopic = useSelector((state) => state.currentTopic);
  const material = useSelector((state) => state.material);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Course
  // const classId = course?.id || null;
  // Topic
  // const topicId = currentTopic?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [bodyExceedMaxChars, setBodyExceedsMaxChars] = useState(false);
  const [title, setTitle] = useState(material?.title || '');
  // Search Params
  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  const materialId = useMemo(() => {
    if (material?.id && contentType === 'materials') {
      return material?.id; // supabase
    }

    const entryId = searchParams.get('entryId'); // contentful

    return entryId || null;
  }, [contentType, material?.id, searchParams]);

  const showSuccess = () => {
    toast.success('Material saved!');
    setLoading(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  // const handleCancel = () => {
  //   if (onCancel) {
  //     return onCancel();
  //   }

  //   history.push(`${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`);
  // };

  const editMaterial = (dataToSave) => {
    // Supabase
    if (contentType === 'materials') {
      if (material?.resourceId) {
        updateResource({
          name: dataToSave?.title || null,
          body: dataToSave?.body || null
        }, material?.resourceId).then(() => {
          updateMaterial(dataToSave, materialId).then(() => {
            if (material?.isEmbedded) {
              toast.info('Embedding...');
              embedMaterial({
                orgId,
                materialId,
                resourceId: material?.resourceId
              }).then(() => {
                showSuccess();
              });
            } else {
              showSuccess();
            }
          });

          if (resourceGroup?.id) {
            // If in Resources, refresh list to show updates
            dispatch(getResourcesByParentId(resourceGroup?.id, 'resourceGroup'));
          }
        });

        return;
      }

      updateMaterial(dataToSave, materialId).then(() => {
        showSuccess();
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      title
    };

    if (bodyRef && bodyRef.current && bodyRef.current.isDirty) {
      dataToSave.body = bodyRef.current.getContent();
    }

    setLoading(true);

    if (materialId) {
      editMaterial(dataToSave);
    }
    //  else {
    //   createNewMaterial(dataToSave);
    // }
  };

  return (
    <div className="card mb-1">
      <form onSubmit={handleSubmit}>
        {showHeader && (
          <div className="card-header bg-light p-3 d-flex align-items-center justify-content-between">
            <h5 className="m-0 text-capitalize d-flex align-items-center">
              <FaFileAlt />
              <span className='ml-2'>
                {materialId && title ? title : 'New Material'}
              </span>
            </h5>

            {/* {materialId && (
              <EntryButton
                title="Preview"
                classId={classId}
                topicId={topicId}
                entryId={materialId}
              />
            )} */}
          </div>
        )}

        <div className="card-body">
          <div className="mb-3">
            <label className="mb-2">
              <b>Title:</b> <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={title}
              onChange={(e) => {
                const { value } = e.currentTarget;

                setTitle(value);
              }}
              autoFocus={!materialId}
              required
            />
          </div>

          {materialId && (
            <RenderTinyMceEditor
              id="material-body"
              contentId={materialId}
              contentType={contentType}
              title="Body"
              defaultValue={material?.body || ''}
              editorRef={bodyRef}
              importMarkdownButton
              uploadPdfButton={uploadPdfButton}
              embedMediaButton={embedMediaButton}
              onExceedMaxChars={(exceedsMaxCharsBool) => {
                setBodyExceedsMaxChars(exceedsMaxCharsBool);
              }}
              {...tinyMceEditor}
            />
          )}
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={loading || bodyExceedMaxChars}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            {/* <button
              className="btn btn-sm btn-link"
              onClick={handleCancel}
              title="Cancel"
              type="button"
              disabled={loading}
            >
              Cancel
            </button> */}
          </div>
        </div>

      </form>
    </div>
  );
};

export default Form;

import { clientManagement, CSPACE } from './contentful';
import convertObjectToUrlParams from '../utils/convertObjectToUrlParams';
import removeKeysWithUndefinedFromObject from '../utils/removeKeysWithUndefinedFromObject';
import { TURBINE } from '../constants/urls';

export const createEntry = ({ contentType, data }) => {
  return new Promise((resolve, reject) => {
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.createEntry(contentType, data))
      .then((entry) => entry.publish())
    // .then((entry) => console.log(`Entry ${entry.sys.id} published.`))
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const updateEntry = ({ id, data }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(id))
      .then((entry) => {
        entry.fields = {
          ...entry.fields,
          ...data.fields
        };

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const manageDataPrep = (data) => {
  if (data.addTypes) data.addTypes = data.addTypes.join(',');

  return removeKeysWithUndefinedFromObject(data);
};

export const manageEntry = (data) => {
  const prepData = manageDataPrep(data);

  return convertObjectToUrlParams(prepData);
};

export const createEntryUrl = (url) => {
  return `${TURBINE}/classes/${url}`;
};

export const idFromContentType = ({
  contentType, classId, topicId, entryId
}) => {
  switch (contentType) {
    case 'class':
      return classId;
    case 'topic':
      return topicId;
    default:
      return entryId;
  }
};

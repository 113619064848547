import {
  ICON_FILE,
  ICON_FILE_EXCEL,
  ICON_FILE_IMAGE,
  ICON_FILE_PDF,
  ICON_LINK,
  ICON_RESOURCE
} from '@apprentage/constants';
// import * as aws from './aws';
import sanitize from 'sanitize-filename';
import { createSupabaseEntry, fetchSupabaseEntries, updateSupabaseEntry } from './supabaseProxy';
import { vectorSearch } from './openAi';
import { fetchDocuments } from './documents';
import {
  SET_MATERIAL_FILES, SET_NOTIFICATION_FILES, SET_PROJECT_FILES, SET_RESOURCE_GROUP_FILES
} from '../actions/types';
import {
  coerceWhiteSpacesToSingle, hyphenToSpace, plusToSpace, unCamelCase, underscoreToSpace
} from './filenames';

const table = 'resources';

export const fileType = (type = '') => {
  switch (type) {
    case 'material':
      return {
        name: 'material',
        icon: ICON_FILE
      };
    case 'link':
      return {
        name: 'link',
        icon: ICON_LINK
      };
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: ICON_FILE_IMAGE
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: ICON_FILE_PDF
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: ICON_FILE_EXCEL
      };
    case 'video/mp4':
      return {
        name: 'video',
        icon: ICON_RESOURCE
      };
    default:
      return {
        name: 'file',
        icon: ICON_FILE
      };
  }
};

export const updateResource = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((updatedResource) => {
      resolve(updatedResource);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createResource = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((newResource) => {
      resolve(newResource);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};

export const coerceToResourceName = ({
  str,
  fixCamelCase = false,
  fixHyphens = false,
  fixUnderScores = false,
  fixPlus = false
}) => {
  let output = str;

  output = sanitize(output);

  if (fixCamelCase) {
    // This flag is needed to prevent unexpected UX
    // while manipulating the str in an input field
    output = unCamelCase(output);
  }

  if (fixHyphens) {
    output = hyphenToSpace(output);
  }

  if (fixUnderScores) {
    output = underscoreToSpace(output);
  }

  // output = fileNameFriendly(output);

  // output = adhereToOSFilenames(output);
  if (fixPlus) {
    output = plusToSpace(output);
  }

  output = coerceWhiteSpacesToSingle(output);

  return output.trimEnd();
};

export const fetchResources = async ({
  orgId,
  parentId,
  parentType,
  name,
  body,
  ids,
  order = 'name',
  select,
  limit = 1000
}) => {
  const params = {
    order,
    limit
  };

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (parentId) {
    params['f.parentId[eq]'] = parentId;
  }

  if (parentType) {
    params['f.parentType[eq]'] = parentType;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchResourcesByVectorText = ({
  orgId,
  locationId,
  userId,
  integrationId,
  saveSearch,
  searchText,
  contentTypes,
  prompt,
  parentType,
  searchGroupIds,
  count
}) => {
  return new Promise((resolve, reject) => {
    vectorSearch({
      orgId,
      locationId,
      userId,
      ...(integrationId ? { integrationId } : {}),
      parentType,
      ...(searchGroupIds ? { parentIds: searchGroupIds } : {}),
      contentTypes,
      saveSearch,
      ...(searchText ? { searchText } : {}),
      ...(prompt ? { prompt } : {}),
      threshold: 0.30,
      count
    }).then((vecResponse) => {
      // Response: [{
      //   id: (embeddingId),
      //   refId: (resourceId),
      //   similarity: (cosign similarity score)
      // }, {...}]
      if (Array.isArray(vecResponse?.items) && vecResponse?.items?.length) {
        const ids = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.id)) {
            // Make sure ids are unique
            acc.push(curr.id);
          }
          return acc;
        }, []);
        const refIds = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.refId)) {
            // Make sure refIds are unique
            acc.push(curr.refId);
          }
          return acc;
        }, []);

        // TODO: Refactor BE to return the documents and resources
        // information that is being fetched here to eliminate these two calls

        fetchDocuments({
          orgId,
          ids,
          select: ['rawText', 'id', 'refId', 'pageNumber', 'metadata'],
          limit: count
        }).then((responseDocs) => {
          fetchResources({
            orgId,
            ids: refIds
          }).then((response) => {
            const vectorNodes = [];
            const groupedEmbeddings = responseDocs.items.reduce((acc, curr) => {
              if (acc[curr.refId] === undefined) {
                acc[curr.refId] = [];
              }
              const resource = response.items.find((r) => r.id === curr?.refId);
              const item = vecResponse.items.find((vec) => vec.id === curr?.id);

              acc[curr.refId].push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              vectorNodes.push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              return acc;
            }, {});

            const vectorFiles = response?.items.reduce((acc, curr) => {
              const item = vecResponse.items.find((vec) => vec.refId === curr?.id);
              acc.push({
                ...curr,
                similarity: item?.similarity,
                embeddings: groupedEmbeddings[curr?.id]
              });

              return acc;
            }, []);

            resolve({
              vectorSearchId: vecResponse?.searchId,
              vectorFileIds: refIds,
              vectorFiles,
              vectorNodes,
              resourceVecIds: vecResponse?.resourceVecIds
            });
          }).catch((error) => {
            console.error(error);
            reject(error);
          });
        });
      } else {
        resolve({ vectorFiles: [] });
      }
    });
  });
};

export const actionFromParentType = (parentType) => {
  // Construct Dispatch Data based on parentType (supabase)
  switch (parentType) {
    case 'projects':
      return SET_PROJECT_FILES;
    case 'notifications':
      return SET_NOTIFICATION_FILES;
    case 'materials':
      return SET_MATERIAL_FILES;
    case 'resourceGroup': // TODO change to resourceGroups
      return SET_RESOURCE_GROUP_FILES;
    default:
      return SET_RESOURCE_GROUP_FILES; // SET_CURRENT_ENTRY_FILES
  }
};

import React from 'react';

const Code = ({
  endpointUrl,
  xApiKey
}) => {
  const code = `
    try {
      const response = await fetch(${endpointUrl}, {
        headers: {
          'x-api-key': ${xApiKey}
        }
      });

      if (response.error) {
        throw new Error(HTTP error!);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      throw new error;
    }
  `;

  return (
    <div>
      <pre style={{ whiteSpace: 'pre-wrap', color: 'white', marginBottom: 0 }}>
        {code}
      </pre>
    </div>
  );
};

export default Code;

import React from 'react';
import { useDispatch } from 'react-redux';
import { ICON_TEXT } from '../../../../constants/assets';
import { SHEET_KEY_MATERIAL } from '../../../../constants/sideSheets';
import { getMaterial } from '../../../../actions/Materials';
import { setSideSheet } from '../../../../actions/SideSheets';

const MaterialLink = ({
  className = '',
  id = null,
  title = 'Widget',
  // body = null,
  height = '40',
  width = '40'
}) => {
  const dispatch = useDispatch();

  const onClickName = () => {
    dispatch(getMaterial(id)).then(() => {
      dispatch(setSideSheet({
        key: SHEET_KEY_MATERIAL,
        className: 'MaterialSideSheet'
      }));
    });
  };

  return (
    <div className={className}>
      <div className="d-flex align-items-center justify-content-between bg-white shadow border rounded p-2">
        <div className="d-flex align-items-center w-100">
          <div className="border bg-white rounded d-block mr-2 position-relative">
            <div
              className="p-2"
              style={{
                height: `${height}px`,
                width: `${width}px`
              }}
            >
              <img
                className="d-block w-100 h-100"
                src={ICON_TEXT}
                alt={title}
                height={height}
                width={width}
              />
            </div>
          </div>
          <div
            className="w-100 d-flex align-items-center justify-content-between flex-auto"
            // className={`w-100 d-flex align-items-center justify-content-between flex-auto ${url ? 'pb-2 ' : ''}`}
            // style={url ? { borderBottom: '1px solid rgba(0, 0, 0, .5)' } : {}}
          >
            <div>
              <div className="font-weight-bold widget-listView-title">
                {title}
              </div>
              {/* <div className="widget-listView-body">{body}</div> */}
            </div>

            <div className="d-flex flex-column">
              <button
                type='button'
                className="mx-1 btn btn-sm btn-white py-1"
                title={title}
                style={{
                  minWidth: '50px',
                  borderRadius: '1rem',
                  fontSize: '.75rem'
                }}
                onClick={onClickName}
              >
                OPEN
              </button>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialLink;

import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { roleReadable } from '@apprentage/utils';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { fetchUsers } from '../../../services/users';
import sortArrByKey from '../../../utils/sortArrByKey';

const Users = ({
  orgId,
  orgType,
  userIds,
  roles,
  onClick,
  children,
  className = '',
  noDataText = 'No users selected.'
}) => {
  // Local State
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [loading, setLoading] = useState(true);

  const hasUserIds = useMemo(() => {
    return Array.isArray(userIds) && userIds.length !== 0;
  }, [userIds]);

  useEffect(() => {
    let mounted = true;

    if (orgId && hasUserIds) {
      fetchUsers({
        userIds,
        roles,
        orgId
      }).then((response) => {
        if (mounted) {
          setSelectedUsers(response?.items || []);
          setLoading(false);
        }
      });
    }

    if (mounted) {
      setLoading(false);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [userIds, orgId, hasUserIds, roles]);

  if (!selectedUsers && !loading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '100px' }}
        >
          {noDataText}
        </div>
        {children && <div className="mt-2">{children}</div>}
      </>
    );
  }

  if (!selectedUsers && loading) {
    return (
      <div className="d-flex align-item-center w-100">
        <Skeleton
          circle
          className="mr-2"
          style={{ height: '40px', width: '40px' }}
        />
        <Skeleton
          count={1}
          style={{ height: '40px' }}
        />
      </div>
    );
  }

  const whichUsers = sortArrByKey(selectedUsers, 'name');

  return (
    <div className={`users d-flex flex-column ${className}`}>
      {Array.isArray(whichUsers) && whichUsers.map((user) => (
        <NameBlock
          key={user?.id}
          name={user?.name}
          nameSize="sm"
          pictureSize="sm"
          title={user?.title || roleReadable(user?.role, orgType)}
          profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
          className={hasUserIds && userIds.length === 1 ? '' : 'mb-3'}
          {...(onClick
            ? {
              onClick: () => {
                onClick(user?.id);
              }
            }
            : {})}
        />
      ))}
      {children && <div className="mt-2">{children}</div>}
    </div>
  );
};

export default Users;

import { fetchSupabaseEntries, fetchSupabaseEntry } from './supabaseProxy';

const table = 'pageLinks';

export const fetchPageLink = async ({ id, select }) => {
  const params = {
    table,
    id
  };

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  const response = await fetchSupabaseEntry(params);

  return response;
};

export const fetchPageLinkByPageId = async ({ pageId, orgId, select }) => {
  const params = {};

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (pageId) {
    params['f.pageId[eq]'] = pageId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  if (Array.isArray(response?.items) && response.items.length) {
    return response.items[0];
  }

  return null;
};

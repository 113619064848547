import React, { useMemo, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import { reactSelectDefaultValue, reactSelectOptionsFromEnum } from '@apprentage/utils/';
import Collapse from '@apprentage/components/dist/components/Collapse';
import ItemsList from './ResponseContent/ItemsList';
import Code from './Code';
import { TW_API } from '../../constants/urls';
import ResponseContent from './ResponseContent';
import TagInput from '../../components/ManageContent/TagInput';
import Filters from './Filters';
import Tabs from './Tabs';
import ResponseAsJson from './ResponseActions/ResponseAsJson';
import ResponseActions from './ResponseActions';

const DEFAULT_ITEMS = [];
const DEFAULT_ORDER = 'name';
const DEFAULT_LIMIT = 20;
const DEFAULT_PAGE = 1;
const DEFAULT_RANGE_FROM = null;
const DEFAULT_RANGE_TO = null;
const DEFAULT_TOTAL = 0;

const Api = () => {
  const [activeTab, setActiveTab] = useState('content');
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //
  const [xApiKey, setXApiKey] = useState('');
  const [contentType, setContentType] = useState('');
  const [action, setAction] = useState('');
  const [endpoint, setEndpoint] = useState('');
  // Response
  const [response, setResponse] = useState(undefined);
  const [items, setItems] = useState(DEFAULT_ITEMS);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rangeFrom, setRangeFrom] = useState(DEFAULT_RANGE_FROM);
  const [rangeTo, setRangeTo] = useState(DEFAULT_RANGE_TO);
  const [total, setTotal] = useState(DEFAULT_TOTAL);
  // Content
  const [id, setId] = useState(undefined);
  const [parentId, setParentId] = useState(undefined);
  const [parentIds, setParentIds] = useState(undefined);
  // Actions
  const [url, setUrl] = useState(undefined);
  const [expires, setExpires] = useState(undefined);

  const onClickRun = () => {
    setError(null);
    setItems([]);
    fetchContent();
  };

  const endpointsEnum = {
    // Actions
    createPublicUrl: 'actions/create-public-url',
    // Content
    resources: 'content/resources',
    resourceGroups: 'content/resourceGroups'
  };

  const actionsEndpointsOptionsEnum = {
    createPublicUrl: 'Create Public URL'
  };

  const contentEndpointsOptionsEnum = {
    resources: 'Resources',
    resourceGroups: 'Resource Groups'
  };

  const contentTypeOptions = reactSelectOptionsFromEnum(contentEndpointsOptionsEnum);
  const contentTypeDefaultValue = reactSelectDefaultValue(contentType, contentTypeOptions);

  const actionsOptions = reactSelectOptionsFromEnum(actionsEndpointsOptionsEnum);
  const actionsTypeDefaultValue = reactSelectDefaultValue(action, actionsOptions);

  const endpointUrl = useMemo(() => {
    let baseUrl = `${TW_API}/v1/api/${endpoint}`;
    const params = {};

    if (activeTab === 'actions') {
      if (url) params.url = url;
      if (expires) params.expires = expires;
    }

    if (activeTab === 'content') {
      params.limit = limit;
      params.order = order;
      params.page = page;

      if (id) params.id = id;

      if (parentId !== undefined) {
        if (parentId === 'null') {
          params['f.parentId[is]'] = null;
        } else {
          params['f.parentId[eq]'] = parentId;
        }
      }

      if (contentType === 'resources') {
        params['f.parentType[eq]'] = 'resourceGroup';
      }

      if (Array.isArray(parentIds) && parentIds.length) {
        params['f.parentIds[ov]'] = parentIds.join(',');
      }
    }

    if (Object.keys(params).length) {
      const searchParams = new URLSearchParams(params);
      baseUrl = `${baseUrl}?${searchParams.toString()}`;
    }
    return baseUrl;
  }, [activeTab, contentType, endpoint, expires, id, limit, order, page, parentId, parentIds, url]);

  const fetchContent = async () => {
    if (endpoint && xApiKey) {
      setLoading(true);
      setFetched(false);
      try {
        const rsp = await fetch(endpointUrl, {
          headers: {
            'x-api-key': xApiKey
          }
        });

        if (!rsp.ok) {
          throw new Error(`HTTP error! status: ${rsp.status}`);
        }

        const data = await rsp.json();
        setResponse(data);

        if (activeTab === 'content') {
          setItems(data?.items || DEFAULT_ITEMS);
          setTotal(data?.total || DEFAULT_TOTAL);
          setRangeFrom(data?.rangeFrom || DEFAULT_RANGE_FROM);
          setRangeTo(data?.rangeTo || DEFAULT_RANGE_TO);
          setPage(data?.page || DEFAULT_PAGE);
          setLimit(data?.limit || DEFAULT_LIMIT);
        }
      } catch (err) {
        setError(err);
      } finally {
        setFetched(true);
        setLoading(false);
      }
    }
  };

  return (
    <div className='container-fluid mt-3'>
      <h1 className='text-white'>Turbine API</h1>
      <div className='row'>
        <div className='col-12 col-sm-6'>

          <div className='text-white d-flex align-items-center mb-3'>
            <div className='h4 mb-0 mr-2'>
              Request Configuration
            </div>
          </div>

          <div className='card mb-3'>
            <div className='card-body'>
              <div className='mb-3'>
                <div className='mb-1'>
                  <strong>Api Key</strong> <span className="text-danger">*</span>
                </div>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setXApiKey(value);
                  }}
                />
              </div>

              <div className='shadow mb-4'>
                <Tabs
                  className='bg-light rounded-tl rounded-tr pt-3 border-top border-left border-right'
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setEndpoint={setEndpoint}
                />

                <div className="row mb-4">
                  <div className="col-12">

                    {activeTab === 'actions' && (
                      <div className='bg-white p-3 border-left border-right border-bottom rounded'>
                        <div className='mb-3'>
                          <div className='mb-1'>
                            <strong>Endpoint</strong> <span className="text-danger">*</span>
                          </div>
                          <SelectMenu
                            name="action"
                            options={actionsOptions}
                            defaultValue={actionsTypeDefaultValue}
                            placeholder='Choose endpoint'
                            required
                            onChange={({ value }) => {
                              setAction(value);
                              setEndpoint(endpointsEnum[value]);
                            }}
                          />
                        </div>
                        <Collapse
                          id="apiParams"
                          title='Params'
                          className='mb-3'
                        >
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Resource URL</strong>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setUrl(value);
                              }}
                            />
                          </div>
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Expires</strong>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setExpires(value);
                              }}
                            />
                            <div className='small text-muted'>
                              Time till public URL expires. Default is <strong>3600</strong>.
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    )}

                    {activeTab === 'content' && (
                      <div className='bg-white p-3 border-left border-right border-bottom rounded'>
                        <div className='mb-3'>
                          <div className='mb-1'>
                            <strong>Endpoint</strong> <span className="text-danger">*</span>
                          </div>
                          <SelectMenu
                            name="type"
                            options={contentTypeOptions}
                            defaultValue={contentTypeDefaultValue}
                            placeholder='Choose endpoint'
                            required
                            onChange={({ value }) => {
                              setContentType(value);
                              setEndpoint(endpointsEnum[value]);

                              if (value === 'resources') {
                                setOrder('name');
                              } else if (value === 'resourceGroups') {
                                setOrder('title');
                              } else {
                                setOrder('createdAt');
                              }
                            }}
                          />
                        </div>
                        <Collapse
                          id="apiParams"
                          title='Params'
                          className='mb-3'
                        >
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>ID</strong>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setId(value || undefined);
                              }}
                            />
                          </div>
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Parent ID</strong>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setParentId(value || undefined);
                              }}
                            />
                            <div className='small text-muted'>
                              use <strong>null</strong> to return top level resource groups
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Parent IDs</strong>
                            </div>
                            <TagInput
                              id="parentIds"
                              name="parentIds"
                              tags={parentIds}
                              onChange={({ parentIds: newParentIds }) => {
                                // null converted to undefined when data is formatted before sent to BE
                                setParentIds(
                                  newParentIds.length === 0 ? undefined : newParentIds
                                );
                              }}
                            />
                          </div>
                        </Collapse>
                        <Collapse
                          id="apiBasics"
                          title='Config'
                        >
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Order</strong>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              value={order}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setOrder(value || DEFAULT_ORDER);
                              }}
                            />
                            <div className='small text-muted'>
                              Use <strong>-</strong> for ascending
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Limit</strong>
                            </div>
                            <input
                              type='number'
                              className='form-control form-control-sm'
                              value={limit}
                              min={DEFAULT_LIMIT}
                              max={3000}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setLimit(value || undefined);
                              }}
                            />
                          </div>
                          <div className='mb-3'>
                            <div className='mb-1'>
                              <strong>Page</strong>
                            </div>
                            <input
                              type='number'
                              className='form-control form-control-sm'
                              value={page}
                              min={1}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setPage(value || undefined);
                              }}
                            />
                          </div>
                        </Collapse>
                      </div>
                    )}

                    {/* {activeTab === 'action' && (
                      <Action className='bg-white shadow p-3 border-left border-right border-bottom rounded' />
                    )} */}

                  </div>
                </div>
              </div>

            </div>
            <div
              className='card-footer d-flex justify-content-end'
            >
              <button
                type="button"
                onClick={onClickRun}
                className='btn btn-sm btn-primary'
              >
                <span className='d-flex align-items-center'>
                  <span className='mr-2'>
                    Run
                  </span>
                  <FaPlay />
                </span>
              </button>
            </div>
          </div>

          {fetched && (
            <div className=''>
              <h4 className='text-white'>
                Response {activeTab === 'content' && (<span>({fetched ? limit : 0} items)</span>)}
              </h4>

              <div className='card mt-3'>
                <div className='card-body'>

                  {error && (
                  <div className='bg-white p-3 rounded shadow'>Error: {error.message}</div>
                  )}

                  {loading && (
                  <div className='bg-white p-3 rounded shadow'>Loading...</div>
                  )}

                  {activeTab === 'actions' && fetched && (
                    <ResponseAsJson
                      response={response}
                    />
                  )}

                  {activeTab === 'content' && fetched && (
                    <ItemsList
                      items={items}
                    />
                  )}

                </div>
              </div>
            </div>
          )}
        </div>

        <div className='col-12 col-sm-6'>
          <div className='text-white d-flex align-items-center mb-3'>
            <div className='h4 mb-0 mr-2'>
              Retrieve Data
            </div>
            <div className='h6 mb-0'>
              <div className='badge badge-warning'>example</div>
            </div>
          </div>

          <div className='card mb-3 border-0'>
            <div className='card-body bg-dark text-white'>
              <Code
                xApiKey={xApiKey}
                endpointUrl={endpointUrl}
              />
            </div>
          </div>

          {activeTab === 'content' && (
            <>
              <div className='text-white d-flex align-items-center mb-3'>
                <div className='h4 mb-0 mr-2'>
                  Content Filters
                </div>
              </div>

              <Collapse
                id="apiFilters"
                title='Filters'
                className='mb-3 bg-white'
              >
                <div className='card mb-3 border-0'>
                  <div className='card-body p-0'>
                    <Filters />
                  </div>
                </div>
              </Collapse>
            </>
          )}

          {fetched && (
            <div>
              <h4 className='text-white'>
                Response Schema
              </h4>
              <div className='card border-0'>
                <div className='card-body bg-dark p-0'>
                  {activeTab === 'actions' && (
                    <ResponseActions
                      endpoint={endpoint}
                    />
                  )}
                  {activeTab === 'content' && (
                    <ResponseContent
                      items={items}
                      page={page}
                      rangeFrom={rangeFrom}
                      rangeTo={rangeTo}
                      limit={limit}
                      total={total}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Api;

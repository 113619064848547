import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PAGE_LINK_MESSAGE_BASE } from '../../constants/routes';
import { getOrg } from '../../actions';
import { getPageLink, resetPageLink } from '../../actions/PageLinks';
import { analyticsTracking } from '../../services/analytics';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../constants/assets';
import { checkAccessCode } from '../../services/access';
import { isLocalhost } from '../../serviceWorker';
import pageInIframe from '../../utils/pageInIframe';
import AuthContainer from '../../components/auth/Container';
import Loading from '../../components/Loading';

const PageLink = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const pageLink = useSelector((state) => state.pageLink);
  // Params
  const pageLinkId = params?.pageLinkId || null;
  // Search Params
  const searchParams = new URLSearchParams(document.location.search);
  const paramsNoTrack = searchParams.has('nt');
  // Access Code
  const [accessCodeApproved, setAccessCodeApproved] = useState(false);
  const ACCESS_CODE_LEN = 6;
  const showAccessCodePrompt = useMemo(() => {
    if (pageLink?.id) {
      if (accessCodeApproved) {
        return false;
      }

      if (pageLink?.hasAccessCode) {
        return true;
      }
    }

    return false;
  }, [pageLink?.id, pageLink?.hasAccessCode, accessCodeApproved]);

  const handleCheckAccessCode = useCallback((accessCode) => {
    toast.dismiss('accessCodeInvalid');

    if (accessCode.length === ACCESS_CODE_LEN) {
      checkAccessCode({
        table: 'pageLinks',
        id: pageLinkId,
        accessCode: parseInt(accessCode, 10)
      }).then((rsp) => {
        if (rsp.success) {
          setAccessCodeApproved(true);
        } else {
          toast.error(rsp.message, { toastId: 'accessCodeInvalid', autoClose: false });
        }
      }).catch((error) => {
        console.error(error);
        toast.error('Error occurred, try again.');
      });
    }
  }, [pageLinkId]);

  useEffect(() => {
    if (pageLinkId) {
      dispatch(getPageLink(pageLinkId)).then((response) => {
      // If no Document found (401) or there's no orgId
        if (!response?.id || !response?.orgId) {
          history.replace(`${PAGE_LINK_MESSAGE_BASE}/error`);
          console.error(response?.message);

          return false;
        }

        dispatch(
          getOrg({
            orgId: response?.orgId,
            select: ['fields.name', 'fields.orgLogo', 'fields.slug'],
            locationIds: [response?.locationId],
            locationSelect: ['fields.name']
          })
        );
      }).catch((error) => {
        history.replace(`${PAGE_LINK_MESSAGE_BASE}/error?id=${pageLinkId}`);
        console.error(error?.message);
      });
    }

    return function cleanup() {
      dispatch(resetPageLink());
    };
  }, [dispatch, history, pageLinkId]);

  useEffect(() => {
    if (!pageInIframe && pageLink?.id && !paramsNoTrack && !isLocalhost) {
      if (sessionStorage.getItem(`pageLink-${pageLinkId}`)) {
        // Page View
        analyticsTracking({
          refTable: 'pageLinks',
          refId: pageLink?.id,
          userId: currentUser?.id || null,
          orgId: pageLink?.orgId
        });
      } else {
        // New Visit & Page View
        analyticsTracking({
          refTable: 'pageLinks',
          refId: pageLink?.id,
          userId: currentUser?.id || null,
          orgId: pageLink?.orgId,
          isUnique: true
        });
      }

      sessionStorage.setItem(`pageLink-${pageLinkId}`, new Date().toISOString());
    }
  }, [currentUser?.id, pageLink?.id, pageLink?.orgId, pageLinkId, paramsNoTrack]);

  if (!pageLinkId) {
    return <Redirect to={PAGE_LINK_MESSAGE_BASE} />;
  }

  if (pageLink?.inactive) {
    return <Redirect to={`${PAGE_LINK_MESSAGE_BASE}/inactive?id=${pageLink?.id}`} />;
  }

  if (pageLinkId && !pageLink?.id) {
    return (
      <Loading
        text="Loading..."
        className="theme-dark"
      />
    );
  }

  // Organization
  if (!organization?.id) {
    return (
      <Loading
        text="Loading..."
        className="theme-dark"
      />
    );
  }

  if (showAccessCodePrompt) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          height: '100vh'
        }}
      >
        <AuthContainer
          title="Access Code"
          footerText="Enter access code to continue."
        >
          <div className="my-4 px-0 px-sm-3 d-flex justify-content-center">
            <ReactCodeInput
              type="text"
              fields={ACCESS_CODE_LEN}
              autoFocus
              onChange={handleCheckAccessCode}
              inputStyle={{}}
            />
          </div>
        </AuthContainer>
      </div>
    );
  }

  if (!pageLink?.enablePageContent && pageLink?.url) {
    setTimeout(() => {
      document.location = pageLink?.url;
    }, 1000);

    return (
      <div
        className='vh-100 text-white d-flex flex-column align-items-center justify-content-center'
      >
        <span
          className="overflow-hidden"
          style={{
            borderRadius: '1.5rem',
            boxShadow: '0px 0px 4rem rgba(255, 255, 255, 0.5)'
          }}
        >
          <img
            src={IMAGE_TURBINE_RETRO_ICON_WHITE}
            height={60}
            style={{
              height: '60px'
            }}
            alt="Turbine"
          />
        </span>
        <div className='mt-3'>
          Redirecting to...
        </div>
        <div className='mt-1'>
          {pageLink?.url}
        </div>
      </div>
    );
  }

  if (pageLink?.pageId) {
    window.localStorage.setItem(`temp-pageLink-${pageLinkId}`, new Date().toISOString());
    // TODO revisit link route structure
    return <Redirect to={`/o/${organization?.slug}/page/${pageLink?.pageId}`} />;
  }

  // TODO show UI "pageLink has an unsupported type"

  return <div>ID: {pageLink?.id}</div>;
};

export default PageLink;

import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchWidgets } from '../../../../services/widgets';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import EmbedWidget from '../../../../components/ManageContent/EmbedWidget';

const EmbedWidgetModule = ({
  className,
  ids,
  orgId = null,
  showNoDataUi = false
}) => {
  // Local State
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  const hasDataIds = useMemo(() => {
    return Array.isArray(ids) && ids?.length > 0;
  }, [ids]);

  const getEmbedClassName = (type, embedCode) => {
    if (type === 'embed') {
      if (embedCode.includes('youtube') || embedCode.includes('vimeo')) {
        return 'embed-container position-relative';
      }
    }
    return '';
  };

  useEffect(() => {
    if (hasDataIds) {
      fetchWidgets({
        orgId,
        type: 'embed',
        ids,
        sortByIds: ids
      }).then((response) => {
        const list = response?.list || [];
        if (list.length > 0) {
          setData(list);
        }
      }).finally(() => {
        setDataFetched(true);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [hasDataIds, ids, orgId]);

  if (loading) {
    return (
      <Skeleton
        style={{ height: '105px' }}
      />
    );
  }

  if (hasDataIds && dataFetched && !data) {
    if (showNoDataUi) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center justify-content-center"
          style={{
            minHeight: '400px'
          }}
        >
          <img
            src={IMAGE_TOOLKIT_EMPTY}
            className="mb-3"
            height={150}
            style={{
              height: '150px'
            }}
            alt="No Media"
          />
          <p className="mt-1 mb-3">No media.</p>
        </div>
      );
    }
  }

  return (
    <div className={`container ${className}`}>
      <div className="d-flex flex-column">
        {data?.map((widget) => {
          const embedClassName = getEmbedClassName(
            widget.type,
            widget?.embedCode
          );
          return (
            <div key={widget.id}>
              <EmbedWidget
                embedCode={widget.embedCode || ''}
                title={widget.title || ''}
                className={embedClassName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmbedWidgetModule;

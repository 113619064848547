import {
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'materials';

export const fetchMaterials = async ({
  orgId,
  userId,
  noteType,
  noteTypeId,
  isDraft,
  workflowType,
  title,
  skillIds,
  resourceId,
  body,
  ids,
  order = 'title',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (resourceId) {
    params['f.resourceId[eq]'] = resourceId;
  }

  if (noteTypeId && noteType) {
    if (noteType === 'apprenticeship') {
      params['f.noteApprenticeshipId[eq]'] = noteTypeId;
    }

    if (noteType === 'user') {
      params['f.noteUserId[eq]'] = noteTypeId;
    }

    if (noteType === 'location') {
      params['f.noteLocationId[eq]'] = noteTypeId;
    }
  }

  if (isDraft) {
    params['f.isDraft[is]'] = isDraft;
  }

  if (workflowType !== undefined) {
    if (workflowType === null) {
      params['f.workflowType[is]'] = workflowType;
    } else {
      params['f.workflowType[eq]'] = workflowType;
    }
  }

  if (skillIds) {
    params['f.skillIds[ov]'] = skillIds.join(',');
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchMaterial = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateMaterial = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};
